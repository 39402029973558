import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "../components/layout";
import Header from "../components/header/header";

const query = graphql`
	query {
		strapiPageHistoriqueDeLaPedagogiePnl {
			title
			slug
			body
			seo {
				metaDescription
				metaTitle
			}
			timeline {
				id
				title
				body
				date
			}
		}
	}
`;

const HistoriqueDeLaPedagogiePnl = () => {
	const data = useStaticQuery(query);
	const page = data.strapiPageHistoriqueDeLaPedagogiePnl;

	return (
		<Layout seo={page.seo}>
			<div className={page.slug}>
				<Header title={page.title}></Header>
				<div className={`container`}>
					<div className="timeline-container sub-container">
						<ul className="timeline">
							{page.timeline.map((line, index) => (
								<li className="timeline__item">
									<div className="item__date">{line.date}</div>
									<div className="item__title">{line.title}</div>
									<p className="item__body">{line.body}</p>
								</li>
							))}
						</ul>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default HistoriqueDeLaPedagogiePnl;
